export const DAYJS_CONFIGS: Record<string, Partial<ILocale>> = {
  en: {
    formats: {
      L: 'MM/DD/YYYY',
      LL: 'dddd, MMMM D YYYY',
      LLLL: 'dddd, MMMM D, YYYY h:mm A',
      LT: 'h:mm A',
      // @ts-expect-error not sure but docs say have this but types say no
      ll: 'D MMM, YYYY',
      l: 'D MMM',
      lll: 'D MMM YYYY h:mm A',
    },
  },
  'zh-cn': {
    formats: {
      L: 'MM/DD/YYYY',
      LL: 'YYYY年M月D日dddd',
      LLLL: 'YYYY年M月D日ddddAh:mm',
      LT: 'A h:mm',
      // @ts-expect-error not sure but docs say have this but types say no
      ll: 'YYYY年M月D日',
      l: 'YYYY年M月',
      lll: 'YYYY年M月D日Ah:mm',
    },
  },
  'zh-hk': {
    formats: {
      L: 'MM/DD/YYYY',
      LL: 'YYYY年M月D日dddd',
      LLLL: 'YYYY年M月D日dddd h:mm A',
      LT: 'h:mm A',
      // @ts-expect-error not sure but docs say have this but types say no
      ll: 'YYYY年M月D日',
      l: 'YYYY年M月',
      lll: 'YYYY年M月D日 h:mm A',
    },
  },
  'pt-br': {
    formats: {
      L: 'MM/DD/YYYY',
      LL: 'dddd, D MMMM YYYY',
      LLLL: 'dddd, D MMMM YYYY, HH:mm',
      LT: 'HH:mm',
      // @ts-expect-error not sure but docs say have this but types say no
      ll: 'D MMMM YYYY',
      l: 'MMMM YYYY',
      lll: 'D MMMM YYYY, HH:mm',
    },
  },
  id: {
    formats: {
      L: 'MM/DD/YYYY',
      LL: 'dddd, D MMMM YYYY',
      LLLL: 'dddd, D MMMM YYYY, h:mm A',
      LT: 'h:mm A',
      // @ts-expect-error not sure but docs say have this but types say no
      ll: 'D MMMM YYYY',
      l: 'MMMM YYYY',
      lll: 'D MMMM YYYY, h:mm A',
    },
  },
  de: {
    formats: {
      L: 'MM/DD/YYYY',
      LL: 'dddd, MMMM D YYYY',
      LLLL: 'dddd, MMMM D, YYYY h:mm A',
      LT: 'h:mm A',
      // @ts-expect-error not sure but docs say have this but types say no
      ll: 'D MMM, YYYY',
      l: 'D MMM',
      lll: 'D MMM YYYY h:mm A',
    },
  },
  it: {
    formats: {
      L: 'MM/DD/YYYY',
      LL: 'dddd, MMMM D YYYY',
      LLLL: 'dddd, MMMM D, YYYY h:mm A',
      LT: 'h:mm A',
      // @ts-expect-error not sure but docs say have this but types say no
      ll: 'D MMM, YYYY',
      l: 'D MMM',
      lll: 'D MMM YYYY h:mm A',
    },
  },
};
